import React from 'react';
import { useOcContext } from '@lib/ocContext';
import cx from 'classnames';
import { Link } from 'gatsby';

const EntryHeader = () => {
  const { ocState } = useOcContext();

  return (
    <div
      className={cx(
        ' pt-[46px] sm:pt-[36px] xl:pt-[77px] pb-[70px] sm:pb-[90px] xl:pb-[146px] relative',
        {
          hidden: ocState.currentStep === 5,
        },
      )}>
      <Link
        to='/'
        className='btn-entry absolute top-11 sm:top-9  xl:top-20  right-0 z-20  '>
        Go Home
      </Link>
      <h3 className='blockH6 mb-3 xl:mb-[30px]'>NZCT Chamber Music Contest</h3>
      <h2 className='blockH1 '>2025 Entry Form</h2>
      {ocState.currentStep !== 5 && (
        <div className='absolute bottom-2 sm:bottom-4 left-0'>
          {ocState.currentStep !== 4 ? (
            <p className='blockH7'>
              <span>{ocState.currentStep}</span> of 3
            </p>
          ) : (
            <p className='blockH7'>Entry Summary</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EntryHeader;
