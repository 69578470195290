import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOcContext } from '@lib/ocContext';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

const BillingDetails = () => {
  const { ocState, dispatch } = useOcContext();
  const [billingDetails, setBillingDetails] = useState(ocState.billingDetails);

  const [rulesAccepted, setRulesAccepted] = useState(false);

  const handleInputChange = (e) => {
    const previousState = billingDetails;
    const { name, value } = e.target;
    previousState[name] = value;
    setBillingDetails(previousState);
    dispatch({ type: 'updateBillingDetails', payload: previousState });
  };

  const handleRulesAccepted = (e) => {
    setRulesAccepted(e.target.checked);
    dispatch({ type: 'updateRulesAndConditions', payload: e.target.checked });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    console.log('submitted');
    // update current step
    dispatch({ type: 'updateCurrentStep', payload: 3 });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <div className='xl:grid grid-cols-12'>
          <div className='col-start-1 col-end-6  entry-title-container  xl:sticky xl:top-5 xl:self-start'>
            <h4 className='blockH4 mb-2'>Billing Details</h4>
            <p>
              Please enter the Billpayer's details below. The Billpayer will be
              sent an invoice for entry fees in the week of 7-11 April 2025.
            </p>
          </div>
          <div className='col-start-8 col-span-full mb-10 xl:mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} id='billing-details-form'>
              <div class='entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.originalBillingName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.originalBillingName?.type === 'required',
                  })}
                  value={ocState?.billingDetails?.originalBillingName}
                  {...register('originalBillingName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='originalBillingName'
                  className='entryLabel pointer-events-none '>
                  Full Name*
                </label>
                {errors.originalBillingName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  name='originalBillingSchool'
                  type='text'
                  placeholder=' '
                  className='entryInput peer focus:border-sand border-sand'
                  onChange={handleInputChange}
                  value={ocState?.billingDetails?.originalBillingSchool}
                />
                <label
                  for='originalBillingSchool'
                  className='entryLabel pointer-events-none '>
                  School Name
                </label>
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.originalBillingEmail?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.originalBillingEmail?.type === 'required',
                  })}
                  value={ocState?.billingDetails?.originalBillingEmail}
                  {...register('originalBillingEmail', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='originalBillingEmail'
                  className='entryLabel pointer-events-none '>
                  Billing Email Address*
                </label>
                {errors.originalBillingEmail?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.originalBillingAddress?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.originalBillingAddress?.type === 'required',
                  })}
                  value={ocState?.billingDetails?.originalBillingAddress}
                  {...register('originalBillingAddress', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='originalBillingAddress'
                  className='entryLabel pointer-events-none '>
                  Billing Address*
                </label>
                {errors.originalBillingAddress?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.originalBillingSuburb?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.originalBillingSuburb?.type === 'required',
                  })}
                  value={ocState?.billingDetails?.originalBillingSuburb}
                  {...register('originalBillingSuburb', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='originalBillingSuburb'
                  className='entryLabel pointer-events-none '>
                  Suburb*
                </label>
                {errors.originalBillingSuburb?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.originalBillingCity?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.originalBillingCity?.type === 'required',
                  })}
                  value={ocState?.billingDetails?.originalBillingCity}
                  {...register('originalBillingCity', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='originalBillingCity'
                  className='entryLabel pointer-events-none '>
                  City*
                </label>
                {errors.originalBillingCity?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.originalBillingTown?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.originalBillingTown?.type === 'required',
                  })}
                  value={ocState?.billingDetails?.originalBillingTown}
                  {...register('originalBillingTown', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='originalBillingTown'
                  className='entryLabel pointer-events-none '>
                  Town*
                </label>
                {errors.originalBillingTown?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='number'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.originalBillingPostcode?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.originalBillingPostcode?.type === 'required',
                  })}
                  value={ocState?.billingDetails?.originalBillingPostcode}
                  {...register('originalBillingPostcode', {
                    onChange: handleInputChange,
                    required: true,
                    valueAsNumber: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='originalBillingPostcode'
                  className='entryLabel pointer-events-none '>
                  Postcode*
                </label>
                {errors.originalBillingPostcode?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={ocState?.rulesAndConditions}
                    checked={ocState?.rulesAndConditions}
                    {...register('rulesAndConditions', {
                      onChange: handleRulesAccepted,
                      required: true,
                      setValueAs: (value) => value,
                      checked: ocState?.rulesAndConditions,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    I understand and agree to abide by the NZCT Chamber Music
                    Contest{' '}
                    <a
                      target='_blank'
                      className='inline-block underline underline-offset-2 z-20'
                      href='https://cdn.sanity.io/files/dogza56w/production/47c68d5aa5e71bd0ae6b9d355d0e61dae6366732.pdf'>
                      Rules and Conditions of Entry (2025).
                    </a>
                  </span>
                </label>
                {errors.rulesAndConditions?.type === 'required' && (
                  <p className='entryError'>
                    Rules and Conditions is required to proceed
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default BillingDetails;
